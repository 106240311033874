import type { bottom } from '@popperjs/core';
<script setup lang="ts">
import { usePageStore } from '@voix/store/pageStore'

const currentResort: any = inject('currentResort')
const { currentPage } = usePageStore()
const currentYear = computed(() => new Date().getFullYear())

const isVisible = ref(false)
function visibilityChanged(entry: { intersectionRatio: number }) {
  if (entry.intersectionRatio * 100 > 0)
    isVisible.value = true
}
</script>

<template>
  <div v-if="currentResort" id="footer" name="footer" class="py-10 relative z-10 text-slate-600">
    <span v-voix-visibility="{ callback: visibilityChanged, threshold: [0, .25] }" />

    <div
      class="bottom bg-sanctuary-primary"
    >
      <SignUpForm
        form-id="A01904B0-3D23-4A65-B86A-EECBC4BD7A70"
        :language="currentPage?.language_code"
      />
    </div>

    <div
      class="flex flex-col items-center lg:flex-row lg:justify-between p-4 text-xs"
    >
      <div class="flex space-x-16 lg:space-x-0">
        <ul class="flex flex-col lg:flex-row">
          <li v-cloak class="my-2 lg:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/terms-conditions">
              Terms & Conditions
            </a>
          </li>
          <li v-cloak class="my-2 lg:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/privacy-policy">
              Privacy Policy
            </a>
          </li>
          <li v-cloak class="my-2 lg:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://www.playaresorts.com/accessibility">
              Accessibility
            </a>
          </li>
        </ul>

        <ul class="flex flex-col lg:flex-row">
          <li v-cloak class="my-2 lg:my-0 mx-2">
            <a
              id="ot-sdk-btn" class="text-xs text-slate-600 ot-sdk-show-settings p-0"
              href="javascript:void(0)"
            >
              Cookie Settings
            </a>
          </li>
          <li v-cloak class="my-2 lg:my-0 mx-2">
            <a class="text-xs text-slate-600" href="https://agentcashplus.com/">
              Travel Professionals
            </a>
          </li>
        </ul>
      </div>

      <div class="text-center mt-8 lg:mt-0">
        &copy; {{ currentYear }} Playa Management USA, LLC All rights reserved
      </div>
    </div>
  </div>
</template>
