<script setup>
import { provide } from 'vue'

const props = defineProps({
  index: {
    type: Number,
    required: true,
  },
  alignCenter: {
    type: Boolean,
    default: true,
  },
})

provide('index', props.index)
</script>

<template>
  <div
    class="accordion-item flex flex-col justify-center" :class="{
      'align-center': alignCenter,
    }"
  >
    <slot />
  </div>
</template>
